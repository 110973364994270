<template>
  <el-card class="box-card full-screen">
    <div ref="pageHead" class="table-page-head">
      <el-row class="table-btns">
        <el-button type="text" size="mini" icon="el-icon-edit" @click="editAreaConfig()">{{$t('addConfig')}}</el-button>
      </el-row>
    </div>
    <div class="data-table" :style="{top:queryTableHeight,height:`calc(100% - ${queryTableHeight} - 70px)`}">
      <el-table :data="tableData" :tree-props="{children: 'children', hasChildren: 'hasChildren'}" row-key="id" default-expand-all v-loading="isloading" element-loading-background="rgba(255,255,255,0.4)" size="mini" height="100%">
        <el-table-column type="selection" fixed="left" width="50"></el-table-column>
        <!-- <el-table-column prop="id" :label="$t('name')"></el-table-column> -->
        <el-table-column prop="name" :label="$t('name')" sortable width="300"></el-table-column>
        <el-table-column prop="shortName" :label="$t('shortName')"></el-table-column>
        <el-table-column prop="numberCode" :label="$t('numberCode')"></el-table-column>
        <el-table-column prop="level" :label="$t('level')">
          <template slot-scope="scope">
            {{$t(scope.row.level==1?'nation':scope.row.level==2?'province':'urban')  }}
          </template>
        </el-table-column>
        <el-table-column :label="$t('extend')" width="80">
          <template slot-scope="scope">
            <el-button size="mini" @click="editAreaConfig(scope.row)" type="primary">{{$t('edit')}}</el-button>
            <!-- <el-button size="mini" type="danger">删除</el-button> -->
          </template>
        </el-table-column>
      </el-table>
    </div>

    <el-drawer :title="$t('editConfig')" :visible.sync="drawer" :direction="'rtl'" :modal="false" :destroy-on-close="true" :wrapperClosable="false" :size="700">
      <el-form :model="areaConfigForm" :rules="rules" ref="areaConfigForm" label-position="top">
        <el-form-item :label="$t('name')" prop="name">
          <el-input size="medium" v-model="areaConfigForm.name" />
        </el-form-item>
        <el-form-item :label="$t('shortName')" prop="shortName">
          <el-input size="medium" v-model="areaConfigForm.shortName" />
        </el-form-item>
        <el-form-item :label="$t('numberCode')" prop="numberCode">
          <el-input size="medium" v-model="areaConfigForm.numberCode" maxlength="3" @blur="autocomplete" />
        </el-form-item>
        <el-form-item :label="$t('level')" prop="level">
          <el-radio-group size="medium" v-model="areaConfigForm.level">
            <el-radio :label="1">{{$t('nation')}}</el-radio>
            <el-radio :label="2">{{$t('province')}}</el-radio>
            <el-radio :label="3">{{$t('urban')}}</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item :label="$t('parentNode')" v-if="areaConfigForm.level>1">
          <el-cascader size="medium" v-model="areaConfigForm.parentIds" filterable :placeholder="$t('searchable')" style="width:100%" :options="selectElements" :props="cascader" />
        </el-form-item>

        <el-form-item>
          <el-button v-if="areaConfigForm.id" size="medium" type="primary" @click="updateSubmit('areaConfigForm')" :loading="isloading">
            {{$t('submit')}}
          </el-button>
          <el-button v-else size="medium" type="primary" @click="addSubmit('areaConfigForm')" :loading="isloading">
            {{$t('submit')}}
          </el-button>
        </el-form-item>
      </el-form>
    </el-drawer>
  </el-card>
</template>
<script>
import country from '@/api/country'
import verification from "@/assets/uilt/verification"
export default {
  name: "areaConfig",
  data () {
    return {
      selectElements: [],
      areaConfigForm: { parentIds: [] },
      //table高度，动态设置
      queryTableHeight: String,
      //table显示内容
      tableData: [],
      areaList: [],
      //显示加载中
      isloading: false,
      //右侧弹框
      drawer: false,
    };
  },
  computed: {
    cascader () { return this.$store.state.format.cascader },
    rules () {
      let verify = new verification(this);
      return {
        name: [{ required: true, message: this.$t('requiredFields'), trigger: 'blur' }],
        shortName: [{ required: true, message: this.$t('requiredFields'), trigger: 'blur' }],
        numberCode: [{ validator: verify.numberCode3, trigger: 'blur' },],
        parentIds: [{ type: 'array', required: true, message: this.$t('requiredFields'), trigger: 'blur' }],
        level: [{ required: true, message: this.$t('requiredFields'), trigger: 'blur' }],
      }
    }
  },
  watch: {
    "areaConfigForm.level" (n) {
      this.setSelectElements(n);
      // if (l) { this.areaConfigForm.parentIds = null }
    }
  },
  methods: {
    getList () {
      let _this = this;
      _this.isloading = true;

      country.getList({
        param: {},
        success: res => {
          _this.areaList = _this.$tool.depthCopy(res.data);

          let tree = res.data.filter(function (t) { return t.parentId === 0 })
          for (let i = 0; i < tree.length; i++) {
            let c = res.data.filter(function (t) { return t.parentId === tree[i].id });
            for (let l = 0; l < c.length; l++) {
              c[l].children = res.data.filter(function (t2) { return t2.parentId === c[l].id }) || undefined
            }
            tree[i].children = c;
          }
          _this.tableData = tree;

          _this.isloading = false;
        }
      })
    },
    /**设置层级下拉框 */
    setSelectElements (level) {
      let _this = this;
      let data = _this.areaList ? JSON.parse(JSON.stringify(_this.areaList)) : [];
      _this.selectElements = [];
      if (level == 2) {
        _this.selectElements = data.filter(function (t) { return t.level == 1; });
      }
      else if (level == 3) {
        let op = data.filter(function (t) { return t.level == 1 });
        op.forEach(function (t) {
          t.children = data.filter(function (t2) { return t2.parentId == t.id });
        })
        _this.selectElements = op;
      }
    },
    /**显示编弹框辑框 */
    editAreaConfig (data) {
      let _this = this;
      _this.areaConfigForm = { level: 1 };
      _this.drawer = true;
      if (data) {
        //获取当前区域ID
        let parent = _this.areaList.find(function (t) { return data && t.id == data.parentId });
        //获取当前区域父元素ID
        let parent2 = _this.areaList.find(function (t) { return parent && t.id == parent.parentId });

        data.parentIds = parent2 ? [parent2.id, parent.id] : parent ? [parent.id] : [];
        _this.areaConfigForm = data;
      }
    },
    /**自动补全 */
    autocomplete () {
      if (!Number(this.areaConfigForm.numberCode)) return;
      let val = this.areaConfigForm.numberCode;
      let sum = 3 - val.length;
      for (let i = 0; i < sum; i++) {
        val = '0' + val;
      }
      this.areaConfigForm.numberCode = val;
    },
    updateSubmit (formName) {
      let _this = this;
      _this.$refs[formName].validate(valid => {
        if (valid) {
          let parentIds = _this[formName].parentIds;
          _this[formName].parentId = parentIds && parentIds.length > 0 ? parentIds[parentIds.length - 1] : _this[formName].parentId || 0;
          country.updateById({
            param: _this[formName],
            success: res => {
              if (res.code == 200) {
                _this.$message({ type: "success", message: _this.$t('operateSuccessfully') });
                _this.getList();
                _this.drawer = false;
              }
              else _this.$message({ type: "warning", message: _this.$t(res.msg) });
            }
          })
        }
        else { return false }
      })
    },
    addSubmit (formName) {
      let _this = this;
      _this.$refs[formName].validate(valid => {
        if (valid) {
          let parentIds = _this[formName].parentIds;
          _this[formName].parentId = parentIds && parentIds.length > 0 ? parentIds[parentIds.length - 1] : 0;
          country.add({
            param: _this[formName],
            success: res => {
              if (res.code == 200) {
                _this.$message({ type: "success", message: _this.$t('operateSuccessfully') });
                _this.getList();
                _this.drawer = false;
              }
              else _this.$message({ type: "warning", message: _this.$t(res.msg) });
            }
          })
        }
        else { return false }
      })
    }
  },
  created () { },
  mounted () {
    let _this = this;
    this.$nextTick(() => {
      let queryTableHeight = _this.$refs.pageHead.offsetHeight + 15;
      _this.queryTableHeight = queryTableHeight + 'px';
      _this.getList();
    })
  }
}
</script>
